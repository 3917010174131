<template>
  <v-container class="mt-md-5">
    <!-- <WidgetAlert /> -->
    <v-row align="start" justify="center">
      <v-col cols="12" md="11" class="mb-md-2" align-self="start">
        <h2
          class="header font-weight-bold my-4 white--text text-center mx-auto"
        >
          CONSULTA ELS HORARIS DE PAS DELS DIFERENTS SERVEIS
        </h2>

        <v-spacer></v-spacer>

        <!-- Cercador Horaris de Pas -->
        <v-expansion-panels v-if="showSearcher" v-model="searchPanel">
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center">
              <h4 class="primary--text">CERCADOR D'HORARIS</h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat v-if="cities" dense color="grey lighten-4">
                <v-form ref="form" v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-select
                          v-model="origen"
                          :items="cities"
                          item-text="city"
                          item-value="id"
                          label="Orígen"
                          v-on:change="setStops(origen.id)"
                          return-object
                          :rules="selectRules"
                          required="true"
                          read-only
                        ></v-select>
                        <v-select
                          v-if="origen"
                          v-model="searchForm.paradaOrigenId"
                          :items="paradesOrigen"
                          item-text="definition"
                          item-value="id"
                          label="Parada"
                          v-on:change="
                            setDestinationCities(searchForm.paradaOrigenId)
                          "
                          :loading="itemsLoading"
                          :rules="selectRules"
                          required="true"
                          read-only
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-select
                          v-model="desti"
                          :items="citiesDesti"
                          item-text="city"
                          item-value="id"
                          label="Destí"
                          v-on:change="setBusStopsDesti(desti.id)"
                          return-object
                          :rules="selectRules"
                          hint="Selecciona primer parada orígen"
                          no-data-text="Selecciona primer parada orígen"
                          required="true"
                          read-only
                        ></v-select>
                        <v-select
                          v-if="desti"
                          v-model="searchForm.paradaDestiId"
                          :items="paradesDesti"
                          item-text="definition"
                          item-value="id"
                          label="Parada"
                          :loading="itemsLoading"
                          :rules="selectRules"
                          required="true"
                          read-only
                        ></v-select>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="searchForm.date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="formatDate"
                              label="Data"
                              prepend-icon="mdi-calendar"
                              readonly
                              required
                              v-bind="attrs"
                              v-on="on"
                              :rules="selectRules"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="searchForm.date"
                            no-title
                            scrollable
                            locale="ca-es"
                            first-day-of-week="1"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">
                              Cancel.lar
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(searchForm.date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" md="2" align-self="center">
                        <v-btn
                          color="success"
                          class="mr-xs-2 mx-lg-auto my-md-1"
                          dark
                          :disabled="!searchBtnEnabled"
                          @click="validate()"
                        >
                          CERCAR
                        </v-btn>
                        <v-btn
                          v-if="origen && desti"
                          class="ml-xs-2 mx-lg-auto my-md-1"
                          style="margin-left: 2rem"
                          color="grey darken-2"
                          dark
                          small
                          @click="reset"
                          >ESBORRAR
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-spacer></v-spacer>

    <!-- Resultat Cerca -->
    <v-row
      v-if="horaris.length > 0 && showSearcher"
      class="text-center mt-2"
      align="start"
      justify="center"
    >
      <v-col cols="12" class="mb-0 pb-0" align-self="start">
        <v-row justify="center" class="mb-0 pb-0">
          <v-col cols="12" class="text-center mb-0 pb-0">
            <h3 class="header font-weight-bold my-4 white--text">
              RESULTAT CERCA
            </h3>
          </v-col>
        </v-row>
      </v-col>

      <!-- Timeline Type Results -->
      <v-col
        v-if="showTimeTable === false"
        cols="12"
        md="auto"
        class="mb-2 mt-0"
        align-self="start"
      >
        <v-card class="mt-7">
          <v-row justify="center" class="py-3">
            <v-col>
              <div class="caption">
                <v-icon x-small>fas fa-map-marker-alt</v-icon> Orígen
              </div>
              <h4 class="subtitle-2">
                {{ currentSearch.selectedCityOrigen.city }}
              </h4>
            </v-col>
            <v-col>
              <div class="caption">
                <v-icon x-small>fas fa-map-marker-alt</v-icon> Destí
              </div>
              <h4 class="subtitle-2">
                {{ currentSearch.selectedCityDesti.city }}
                <!-- {{ desti.city }} -->
              </h4>
            </v-col>
            <v-col>
              <div class="caption">
                <v-icon x-small>fas fa-calendar-day</v-icon> Data
              </div>
              <h4 class="subtitle-2">
                <!-- {{ selectedDate }} -->
                {{ formatDate }}
              </h4>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-timeline align-center dense>
            <v-timeline-item color="green">
              <div class="caption text-left">
                <v-icon left small>
                  fa fa-bus
                </v-icon>
                Parada orígen
              </div>
              <v-row>
                <v-col cols="auto">
                  <div class="body-2 pr-2 text-justify">
                    {{ currentSearch.selectedParadaOrigen.definition }}
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>

            <!-- Schedules loop -->
            <v-timeline-item
              v-for="(hora, index) in horaris"
              :key="index"
              color="grey"
              small
            >
              <v-row>
                <v-col cols="auto">
                  <strong>{{ hora.departure | formatTimeString }}</strong>
                </v-col>
                <v-col class="text-left">
                  <div class="body-2">
                    {{ hora.line }}
                  </div>
                </v-col>
                <v-col v-if="hora.type" class="text-left">
                  <div class="body-2">
                    {{ hora.type }}
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
            <!-- End Schedules loop -->

            <v-timeline-item color="blue">
              <div class="caption text-left">
                <v-icon left small>
                  fa fa-bus
                </v-icon>
                Parada destí
              </div>
              <v-row>
                <v-col cols="auto">
                  <div class="body-2 pr-2 text-justify">
                    {{ currentSearch.selectedParadaDesti.definition }}
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>

      <!-- Table Type Results -->
      <v-col v-else cols="12" md="6" class="mb-2 mt-0" align-self="start">
        <v-data-table
          :headers="headers"
          :header-props="headerProps"
          :items="horaris"
          item-key="id"
          hide-default-footer
          :items-per-page="100"
          class="elevation-1 pt-1 mt-2"
        >
          <template v-slot:top>
            <v-toolbar flat class="pb-5 mb-3" prominent>
              <v-row justify="center">
                <v-col>
                  <div class="caption">
                    <v-icon x-small>fas fa-map-marker-alt</v-icon> Orígen
                  </div>
                  <h4 class="subtitle-2">
                    {{ currentSearch.selectedCityOrigen.city }}
                  </h4>
                  <h5>{{ currentSearch.selectedParadaOrigen.definition }}</h5>
                </v-col>
                <v-col>
                  <div class="caption">
                    <v-icon x-small>fas fa-map-marker-alt</v-icon> Destí
                  </div>
                  <h4 class="subtitle-2">
                    {{ currentSearch.selectedCityDesti.city }}
                  </h4>
                  <h5>{{ currentSearch.selectedParadaDesti.definition }}</h5>
                </v-col>
                <v-col>
                  <div class="caption">
                    <v-icon x-small>fas fa-calendar-day</v-icon> Data
                  </div>
                  <h4 class="subtitle-2">{{ formatDate }}</h4>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-divider></v-divider>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.line="{ item }">
            <span>{{ item.line }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.departure="{ item }">
            <span>{{ item.departure | formatTimeString }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row v-if="noResultsMessage.show" justify="center">
      <v-alert dense type="info" class="my-5" dismissible>
        Aquesta cerca no disposa d'horaris.
      </v-alert>
    </v-row>

    <v-row v-if="errorSchedules" justify="center">
      <v-alert dense type="error" class="my-2">
        Oops sembla que hi ha hagut algun problema per obtenir la informació
        dels horaris. <br />
        {{ errorSchedules }}
      </v-alert>
    </v-row>

    <v-row justify="center" class="mt-4">
      <v-col cols="12" md="9">
        <h3 class="subheader white--text mt-5 text-center">TOTS ELS SERVEIS</h3>
        <p class="text-center white--text mt-1">
          A continuació et mostrem els serveis actius actualment. Pots
          descarregar-te també l'horari en format PDF.
        </p>
      </v-col>
    </v-row>

    <v-row v-if="errorLines" justify="center">
      <v-alert dense type="error" class="my-2">
        Oops sembla que hi ha hagut algun problema per obtenir la informació de
        línies. <br />
        {{ errorLines }}
      </v-alert>
    </v-row>

    <v-row v-if="isLoading" justify="center">
      <v-col cols="1">
        <v-progress-circular
          indeterminate
          size="80"
          width="7"
          color="warning"
          class="ml-auto mr-auto"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row v-else align="start" justify="center" class="mt-3 mb-3">
      <v-col cols="12" md="4" v-for="(line, index) in lines" :key="index">
        <CardHorari :line="line" />
      </v-col>
    </v-row>

    <!-- Logo pre-footer -->
    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { parseISO } from "date-fns";
import CardHorari from "@/components/CardHorari";
// import WidgetAlert from "@/components/WidgetAlert";

export default {
  name: "horaris",

  data: () => ({
    // logged: false
    isLoading: true,
    searchPanel: 0,
    valid: false,
    errorLines: null,
    errorCities: null,
    errorSchedules: null,
    searchForm: {
      paradaOrigenId: null,
      paradaDestiId: null,
      date: new Date().toISOString().substr(0, 10)
    },
    currentSearch: {
      selectedCityOrigen: {},
      selectedCityDesti: {},
      selectedParadaOrigen: {},
      selectedParadaDesti: {},
      selectedDate: null
    },
    menu: false,
    lines: null,
    cities: [],
    citiesDesti: [],
    origen: null,
    desti: null,
    paradesOrigen: [],
    paradesDesti: [],
    showResults: false,
    itemsLoading: false,
    horaris: [],

    listType: false,

    selectRules: [v => !!v || "Selecciona una opció"],

    // ** Horaris Results **//
    defaultColor: "gray darken-2",
    // Fake data
    headers: [
      {
        text: "HORA",
        align: "center",
        value: "departure",
        sortable: false
      },
      {
        text: "LÍNIA",
        value: "line",
        sortable: false
      },
      {
        text: "TIPUS",
        value: "type",
        sortable: false
      }
    ],
    headerProps: {
      sortByText: "Ordenar"
    },
    noResultsMessage: {
      show: false,
      text: "No hi ha dades"
    },
    showSearcher: true,
    showTimeTable: false
  }),

  components: {
    CardHorari
    // WidgetAlert
  },

  computed: {
    formatDate() {
      return this.formatDateEs();
    },
    searchBtnEnabled() {
      let valid;
      if (
        this.searchForm.paradaOrigenId != null &&
        this.searchForm.paradaDestiId != null &&
        this.searchForm.date != null
      ) {
        valid = true;
      } else {
        valid = false;
      }
      return valid;
    }
    // showSearcher() {
    //   const userPrefs = this.$store.getters.getUserPrefs;
    //   return userPrefs.scheduleSearcher;
    // },
    // showTimeTable() {
    //   const userPrefs = this.$store.getters.getUserPrefs;
    //   return userPrefs.scheduleTimeTable;
    // }
    // formatTime(value) {
    //   return this.formatTimeHM(value);
    // }
  },

  created() {
    this.setLines();
    this.setCities();
    // if (this.$vuetify.breakpoint.mobile) {
    //   this.searchPanel = null;
    // }
  },

  mounted() {
    if (this.$store.getters.loggedIn) {
      this.setUserPrefs();
    }
  },

  methods: {
    setUserPrefs() {
      // this.showSearcher = this.$store.getters.getUserPrefs.scheduleSearcher;
      this.showTimeTable = this.$store.getters.getUserPrefs.scheduleTimeTable;
    },
    async setLines() {
      await this.$store
        .dispatch("setLines")
        .then(response => {
          // console.log("Lines: " + response.data.length);
          this.lines = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          this.errorLines = error;
          this.isLoading = false;
        });
    },
    async setCities() {
      await this.$store
        .dispatch("setCities")
        .then(response => {
          // console.log("Cities: " + response.data.length);
          this.cities = response.data;
        })
        .catch(error => {
          this.errorCities = error;
        });
    },
    async setStops(cityId) {
      this.citiesDesti = [];
      this.paradesDesti = [];
      this.searchForm.paradaDestiId = null;
      this.itemsLoading = true;
      await this.$store
        .dispatch("setStops", cityId)
        .then(response => {
          // console.log("BusStopsOrigen: " + response.data.origen.length);
          this.paradesOrigen = response.data;
          this.itemsLoading = false;
        })
        .catch(error => {
          this.errorCities = error;
        });
    },
    async setDestinationCities(stopId) {
      this.itemsLoading = true;
      this.citiesDesti = [];
      this.desti = null;
      this.searchForm.paradaDestiId = null;
      await this.$store
        .dispatch("setDestinationCities", stopId)
        .then(response => {
          // console.log("BusStopsOrigen: " + response.data.origen.length);
          this.citiesDesti = response.data;
          this.itemsLoading = false;
        })
        .catch(error => {
          this.errorCities = error;
        });
    },
    async setBusStopsDesti(cityDesti) {
      this.searchForm.paradaDestiId = null;
      this.itemsLoading = true;
      await this.$store
        .dispatch("setBusStopsDesti", cityDesti)
        .then(response => {
          // console.log("BusStopsDesti: " + response.data.desti.length);
          this.paradesDesti = response.data;
          this.itemsLoading = false;
        })
        .catch(error => {
          this.errorCities = error;
        });
    },
    formatDateEs() {
      // Filtre global amb date-fns (vue-date-fns) definit a main.js
      return this.$dateTimeEUformat(
        parseISO(this.searchForm.date),
        "dd-MM-yyyy"
      );
    },
    // formatTimeHM(value) {
    //   return this.$options.filters.formatTimeString(value);
    // },
    reset() {
      this.$refs.form.reset();
      this.searchForm = {
        paradaOrigenId: null,
        paradaDestiId: null,
        date: new Date().toISOString().substr(0, 10)
      };
      this.currentSearch = {
        selectedCityOrigen: {},
        selectedCityDesti: {},
        selectedParadaOrigen: {},
        selectedParadaDesti: {},
        selectedDate: null
      };
    },
    validate() {
      let formValid = this.$refs.form.validate();
      if (formValid === true) {
        this.saveCurrentSearch();
        this.searchSchedules();
      }
    },
    saveCurrentSearch() {
      let data = this.searchForm.date;
      let cityOrigen = this.cities.find(item => item.id === this.origen.id);
      let cityDesti = this.cities.find(item => item.id === this.desti.id);
      let paradaOrigen = this.paradesOrigen.find(
        item => item.id === this.searchForm.paradaOrigenId
      );
      let paradaDesti = this.paradesDesti.find(
        item => item.id === this.searchForm.paradaDestiId
      );
      this.currentSearch = {
        selectedCityOrigen: cityOrigen,
        selectedCityDesti: cityDesti,
        selectedParadaOrigen: paradaOrigen,
        selectedParadaDesti: paradaDesti,
        selectedDate: data
      };
      this.$store.dispatch("setCurrentSearch", this.currentSearch);
    },
    searchSchedules() {
      let isValid = this.valid;
      let searchData = this.searchForm;
      if (isValid) {
        this.noResultsMessage.show = false;
        this.isLoading = true;
        this.$store
          .dispatch("setSchedules", searchData)
          .then(response => {
            // console.log("Horaris: " + response.data);
            this.horaris = response.data;
            if (this.horaris.length == 0) {
              this.noResultsMessage.show = true;
              this.noResultsMessage.text =
                "No s'han trobat horaris per aquesta cerca";
            }
            this.isLoading = false;
          })
          .catch(error => {
            this.errorSchedules = error;
            this.isLoading = false;
          });
      }
    }
  }
};
</script>
